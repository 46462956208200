import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Case from '../views/Case.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'
import Safe from '../views/noticeProject/Safe.vue'
import Profession from '../views/noticeProject/Profession.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/h5-register',
    name: 'index',
    component: () => import('@/views/h5/Index')
  },
  {
    path: '/h5-training-register',
    name: 'index',
    component: () => import('@/views/h5/training/Index')
  },
  {
    path: '/h5-training-paymentDetail',
    name: 'index',
    component: () => import('@/views/h5/training/PaymentDetail')
  },
  {
    path: '/h5-paymentDetail',
    name: 'paymentDetail',
    component: () => import('@/views/h5/PaymentDetail')
  },
  {
    path: '/h5-new-training-detail',
    name: 'newTrainingDetail',
    component: () => import('@/views/h5/newTrainingList/Index')
  },
  {
    path: '/h5-search',
    name: 'Search',
    component: () => import('@/views/h5/projectFollow/Search')
  },
  {
    path: '/h5-search/result',
    name: 'Result',
    component: () => import('@/views/h5/projectFollow/ProjectList')
  },
  {
    path: '/h5-search/result/info',
    name: 'Info',
    component: () => import('@/views/h5/projectFollow/FollowInfo')
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/service-project',
    name: 'service-project',
    component: () => import('@/views/serviceProject/Index'),
    children: [
      {
        path: 'aqzx',
        name: 'service-project-aqzx',
        component: () => import('@/views/serviceProject/AQZX')
      },
      {
        path: 'aqpj',
        name: 'service-project-aqpj',
        component: () => import('@/views/serviceProject/AQPJ')
      },
      {
        path: 'gcxm',
        name: 'service-project-gcxm',
        component: () => import('@/views/serviceProject/GCXM')
      },
      {
        path: 'aqscxxh',
        name: 'service-project-aqscxxh',
        component: () => import('@/views/serviceProject/AQSCXXH')
      },
      {
        path: 'other',
        name: 'service-project-other',
        component: () => import('@/views/serviceProject/Other')
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/case',
    name: 'case',
    component: Case
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/notice-project/safe',
    name: 'notice-projectSafe',
    component: Safe
  },
  {
    path: '/notice-project/profession',
    name: 'notice-projectProfession',
    component: Profession
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0)
  next()
})

export default router
