<template>
  <div>
    <!--  公司证书  -->
    <div class="container">
      <div class="company" style="margin-top: 60px">
        资质&专利
      </div>
      <div class="slogan">
        安胜达始终坚持高质量发展路线，注重自主研发与科技创新能力，拥有HSE行业多项重磅资质证书与专利技术
      </div>
    </div>
    <div style="padding-bottom: 60px;display: flex;flex-direction: column;align-items: center">
      <img src="@/assets/img/qyry1.png" alt="">
      <img src="@/assets/img/qyry2.png" alt="" style="margin: 60px 0;">
      <img src="@/assets/img/qyry3.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyHonor'
}
</script>

<style scoped>
.company {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-bottom: 15px;
}

.slogan {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-bottom: 40px;
}

.certificate {
  flex: 1;
  width: 300px;
  padding: 20px;
  border: 1px solid #CCCCCC;
  margin: 0 10px;
}

.honor {
  width: 1540px;
  margin: 0 auto;
  background: #F2F2F2;
  padding: 60px 20px 47px 20px;
}

.honorList {
  display: flex;
  flex-wrap: wrap;
}

.pic {
  width: 33%;
  margin: 2px;
  border: 1px dashed #D9D9D9;
}

.gray {
  width: 100%;
  background: #F2F2F2;
}
</style>
