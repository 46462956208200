<template>
  <div>
    <Head></Head>
    <!--  轮播  -->
    <ComCarousel></ComCarousel>
    <div class="menu">
      <div class="tab">
        <div :class="tabActive[0]" @click="activeTab(0)">
          公司介绍
        </div>
        <div :class="tabActive[1]" @click="activeTab(1)">
          企业荣誉
        </div>
<!--        <div :class="tabActive[2]" @click="activeTab(2)">-->
<!--          新闻资讯-->
<!--        </div>-->
      </div>
    </div>
    <company-desc v-if="tabActive[0] === 'active'"></company-desc>
    <company-honor v-if="tabActive[1] === 'active'"></company-honor>
    <company-news v-if="tabActive[2] === 'active'"></company-news>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import CompanyDesc from '../components/CompanyDesc'
import CompanyHonor from '../components/CompanyHonor'
import CompanyNews from '../components/CompanyNews'
import ComCarousel from '@/components/ComCarousel'

export default {
  name: 'About',
  data () {
    return {
      tabActive: ['active', '', '']
    }
  },
  components: {
    ComCarousel,
    CompanyNews,
    CompanyHonor,
    Head,
    Footer,
    CompanyDesc
  },
  methods: {
    activeTab (index) {
      for (let i = 0; i < this.tabActive.length; i++) {
        this.$set(this.tabActive, i, '')
      }
      this.$set(this.tabActive, index, 'active')
    }
  },
  mounted () {
    if (this.$route.query.tabActive) {
      switch (this.$route.query.tabActive) {
        case '1':
          this.tabActive = ['active', '', '']
          break
        case '2':
          this.tabActive = ['', 'active', '']
          break
        case '3':
          this.tabActive = ['', '', 'active']
          break
        default:
          this.tabActive = ['active', '', '']
          break
      }
    }
  }
}
</script>

<style scoped>
.menu {
  width: 100%;
  background: #F2F2F2;
}

.tab {
  width: 1540px;
  padding: 0 20px;
  margin:0 auto;
  height: 70px;
  display: flex;
}

.tab div {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 70px;
  color: #333333;
  text-align: center;
  cursor: pointer;
}

.active {
  background: #42C348;
  color: #FFFFFF !important;
}

</style>
