<template>
  <div class="container">
    <div class="newsList">
      <div class="pic" v-for="item in 9" :key="item" @click="goto()">
        <img style="width: 100%;" src="@/assets/img/glln.png" alt="">
        <p class="title">走进安胜达</p>
        <p class="text">江苏安胜达安防技术有限公司于2014年创立，注册资金1263.16万人民币，是一家集企业安全、环境、职业卫生领域相关技术咨询及服务</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyNews',
  methods: {
    goto () { this.$router.push('/news') }
  }
}
</script>

<style lang="scss" scoped>
.newsList {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pic {
  width: 32%;
  margin: 10px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}
.pic:hover{
  box-shadow: 0 2px 20px 0 rgba(205, 205, 205, 0.5);
  transform: translateY(-5px);
  .title {
    color: #4ABF50;
  }
}
.title{
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  padding: 10px;
}
.text{
  font-weight: 400;
  color: #666666;
  font-size: 14px;
  padding:0 10px 10px 10px;
}

</style>
