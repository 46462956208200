import * as Request from '../Request'

const Follow = {
  // 查询某企业的id
  search: (data) => {
    return Request.get('https://oa.asdsafe.cn:18000/applet/list/customer', data)
  },
  // 根据企业id查询用户
  searchUserList: (data) => {
    return Request.get('https://oa.asdsafe.cn:18000/applet/list/contact', data)
  },
  // 验证
  submit: (data) => {
    return Request.post('https://oa.asdsafe.cn:18000/applet/list/project', data)
  },

  // 项目详情
  info: (data) => {
    return Request.get('https://oa.asdsafe.cn:18000/applet/list/follow', data)
  }
}
export default Follow
