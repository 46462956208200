<template>
  <div class="footer">
    <div class="container">
      <div class="display-flex jc-space-between" style="padding: 40px 0 60px 0;">
        <div class="display-flex">
          <div class="mr-60">
            <b>关于我们</b>
            <p @click="$router.push('/about?tabActive=1')">公司介绍</p>
            <p @click="$router.push('/about?tabActive=2')">企业荣誉</p>
<!--            <p @click="$router.push('/about?tabActive=3')">新闻资讯</p>-->
          </div>
          <div class="mr-60">
            <b>服务项目</b>
            <p @click="$router.push('/service-project/aqzx')">安全咨询</p>
            <p @click="$router.push('/service-project/aqpj')">安全评价</p>
            <p @click="$router.push('/service-project/gcxm')">工程项目</p>
            <p @click="$router.push('/service-project/aqscxxh')">安全生产信息化</p>
            <p @click="$router.push('/service-project/other')">其他安全服务</p>
          </div>
          <div class="mr-60">
            <b>客户案例</b>
            <p @click="$router.push('/about')">客户案例</p>
          </div>
          <div class="mr-60">
            <b>联系我们</b>
            <p @click="$router.push('/contact')">公司信息</p>
            <p @click="$router.push('/contact')">公司地图</p>
          </div>
        </div>
        <div class="display-flex">
          <div class="tel">
            <b>0512-62763925</b>
            <p>24小时服务热线</p>
          </div>
          <div class="code">
            <img src="@/assets/img/wechat-code.png" alt="">
            <p>安胜达微信公众号</p>
          </div>
        </div>
      </div>
      <div class="copy">
        <p>Copyright:2018-2026 江苏安胜达安全科技有限公司</p>
        <p class="ml-20" @click="window.open('http://beian.miit.gov.cn/')">备案号：苏ICP备20041520号-1</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      window
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
  .footer {
    b {
      display: block;
      padding-bottom: 16px;
    }
    background-color: #333333;
    color: #FFFFFF;
    font-size: 14px;
    p {
      font-size: 12px;
      opacity: 0.6;
      cursor: pointer;
    }
    .copy {
      border-top: 2px solid #585858;
      width: 100%;
      color: #CCCCCC;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
    }
    .mr-60 {
      margin-right: 60px;
    }
    .tel {
      margin-right: 60px;
      b {
        font-size: 22px;
      }
    }
    .code {
      height: 110px;
      padding-left: 60px;
      border-left: 2px solid #585858;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 68px;
        margin-bottom: 10px;
      }
    }
  }
</style>
