<template>
  <div>
    <Head></Head>
    <div class="main">
      <div style="display: flex;flex-direction: row-reverse">
        <Form class="mt-20" ref="searchForm" :model="searchForm" inline>
          <FormItem prop="user">
            <DatePicker
              v-model="searchForm.date"
              format="yyyy-MM-dd"
              type="daterange"
              placeholder="选择时间段"
              style="width: 200px"></DatePicker>
          </FormItem>

<!--          <FormItem prop="typeId">-->
<!--            <Select v-model="searchForm.typeId" style="width:200px"  placeholder="通知类型">-->
<!--              <Option  label="全部" value="0"></Option>-->
<!--              <Option  label="内部通知" value="1"></Option>-->
<!--              <Option  label="安全评价" value="2"></Option>-->
<!--              <Option  label="职业卫生" value="3"></Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

          <FormItem prop="password">
            <Input v-model="searchForm.fuzzy" placeholder="关键词搜索"> </Input>
          </FormItem>
          <FormItem class="mr-0">
            <Button type="primary" @click="getTableData">搜索</Button>
          </FormItem>
        </Form>
      </div>
      <Table border :columns="columns" :data="data">
        <template slot-scope="{ row }" slot="title">
          <span>{{ row.title }}</span>
        </template>
        <template slot-scope="{ row }" slot="createTime">
          <span>{{ row.createTime }}</span>
        </template>
        <template slot-scope="{ row }" slot="action">
          <span >
            <Button type="primary" class="mr-10" @click="detail(row)">查看详情</Button>
          </span>
        </template>
      </Table>
      <div style="display: flex;flex-direction: row-reverse;margin-bottom: 20px;">
        <Page
          class="mt-20"
          @on-change="(val) => {searchForm.page = val}"
          :current="searchForm.page"
          :page-size="searchForm.limit"
          :total="total"
          show-total />
      </div>
    </div>
    <Footer></Footer>
    <Modal
      width="800px"
      v-model="modal"
      footer-hide
      :title="info.title">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <p style="margin-right: 40px;">发布人：{{info.creatorName}}</p>
          <p  style="margin-right: 30px;">发布时间：{{info.createTime}}</p>
          <p>通知类型：
            <span  v-if="info.typeId==='0'">全部</span>
            <span  v-if="info.typeId==='2'">安全评价</span>
            <span  v-if="info.typeId==='3'">职业卫生</span>
          </p>
        </div>
<!--        <Button type="success" @click="exportPDF(info)">导出PDF</Button>-->
      </div>
      <div class="html" v-html="info.html" style="max-height: 500px;overflow: auto;margin-top: 20px;margin-bottom: 20px;"></div>
      <div v-for="item in info.attachmentList" :key="item.id" style="display: flex;align-items: center">
        {{item.fileName}}
        <span style="color: #0D7EE3;margin-left: 10px;cursor:pointer;" @click="download(item)">下载</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import axios from 'axios'
export default {
  name: 'Notice',
  components: { Head, Footer },
  props: {},
  data () {
    return {
      info: {},
      modal: false,
      searchForm: {
        typeId: '2',
        date: [],
        fuzzy: '',
        page: 1,
        limit: 10
      },
      columns: [
        {
          title: '通知名称',
          slot: 'title',
          tooltip: true
        },
        {
          title: '发布时间',
          slot: 'createTime',
          align: 'center',
          width: '240px'
        },
        {
          title: '操作',
          slot: 'action',
          width: '160px',
          align: 'center'
        }
      ],
      data: [],
      total: 0
    }
  },
  filter: {},
  watch: {
    _searchForm: {
      handler: function (newVal, oldVal) {
        if (newVal.page !== 1 && newVal.page === oldVal.page) {
          this.searchForm.page = 1
        } else {
          this.getTableData()
        }
      },
      deep: true
    }
  },
  computed: {
    /**
     * 监听复杂数据类型的变换
     */
    _searchForm () {
      return JSON.parse(JSON.stringify(this.searchForm))
    }
  },
  methods: {
    getTableData () {
      const data = {
        endDateTime: this.searchForm.date[1] ? this.$moment(this.searchForm.date[1]).format('YYYY-MM-DD HH:mm:ss') : null,
        fuzzy: this.searchForm.fuzzy,
        pageNum: this.searchForm.page,
        pageSize: this.searchForm.limit,
        typeId: this.searchForm.typeId,
        screen: 0,
        startDateTime: this.searchForm.date[0] ? this.$moment(this.searchForm.date[0]).format('YYYY-MM-DD HH:mm:ss') : null
      }
      axios.post('https://oa.asdsafe.cn:18000/notice/website/list', data).then(res => {
        this.data = res.data.data.list
        this.total = res.data.data.total
      })
    },
    detail (row) {
      axios.get(`https://oa.asdsafe.cn:18000/notice/website/detail?id=${row.id}`).then(res => {
        this.info = res.data.data
        this.modal = true
      })
    },

    download (row) {
      const eleLink = document.createElement('a')
      eleLink.style.display = 'none'
      eleLink.href = `https://oa.asdsafe.cn:18000/notice/website/attachment/download?id=${row.id}`
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
    },
    exportPDF (row) {
      const eleLink = document.createElement('a')
      eleLink.style.display = 'none'
      eleLink.href = `https://oa.asdsafe.cn:18000/notice/website/exportAsPDF?id=${row.id}`
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
    }
  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    this.getTableData()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
  .main {
    margin: 0 auto;
    width: 1000px;
    min-height: calc(100vh - 347px);
  }
</style>
<style lang="scss">
  .html {
    table {
      border-top: 1px solid #000000;
      border-left: 1px solid #000000;
      border-collapse: collapse;
      td {
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
      }
    }
  }
</style>
