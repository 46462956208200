<template>
  <div>
    <Head></Head>
    <!--  轮播  -->
    <div class="container">
      <Form style="width: 600px;margin: 60px auto" ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100">
            <FormItem label="学员姓名" prop="name">
              <Input v-model="formValidate.name" placeholder="请输入学员姓名"></Input>
            </FormItem>
            <FormItem label="联系电话" prop="phone">
              <Input v-model="formValidate.phone" placeholder="请输入联系电话"></Input>
            </FormItem>
            <FormItem label="身份证号" prop="idCard">
              <Input v-model="formValidate.idCard" placeholder="请输入身份证号"></Input>
            </FormItem>
            <FormItem label="性别" prop="sex">
              <Select v-model="formValidate.sex" placeholder="请选择性别">
                <Option :value="0">男</Option>
                <Option :value="1">女</Option>
              </Select>
            </FormItem>
            <FormItem label="单位名称" prop="companyName">
              <Input v-model="formValidate.companyName" placeholder="请输入单位名称"></Input>
            </FormItem>
            <FormItem label="职称">
              <Input v-model="formValidate.jobName" placeholder="请输入职称"></Input>
            </FormItem>
            <FormItem label="学历">
              <Input v-model="formValidate.education" placeholder="请输入学历"></Input>
            </FormItem>
            <FormItem label="班次" prop="classesId">
              <Select v-model="formValidate.classesId" placeholder="请选择班次">
                <Option v-for="item in classOptions" :key="item.id" :value="item.id">{{ item.className }}</Option>
              </Select>
            </FormItem>
            <FormItem label="培训项目" prop="eduProject">
              <Select v-model="formValidate.eduProject" placeholder="请选择培训项目">
                <Option :value="1">安全初训</Option>
                <Option :value="2">安全复训</Option>
              </Select>
            </FormItem>
            <FormItem label="培训类型" prop="eduType">
              <Select v-model="formValidate.eduType" placeholder="请选择培训类型">
                <Option :value="1">安全管理员</Option>
                <Option :value="2">企业负责人</Option>
                <Option :value="3">班组长</Option>
              </Select>
            </FormItem>
            <FormItem label="邮箱" prop="email">
              <Input v-model="formValidate.email" placeholder="请输入邮箱"></Input>
            </FormItem>
            <FormItem label="微信号">
              <Input v-model="formValidate.wx" placeholder="请输入微信号"></Input>
            </FormItem>
        <FormItem>
          <Button :loading="loading" type="primary" @click="handleSubmit('formValidate')">提交</Button>
          <Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </div>
    <Footer></Footer>
    <Modal
      width="280"
      footer-hide
      title="对话框标题"
      :mask-closable="false"
      :styles="{top: '25vh'}"
      v-model="modal">
      <p slot="header" style="text-align:center;font-size: 14px">
        微信扫码支付
      </p>
      <div v-if="success" style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 288px">
        <Icon color="#19be6b" type="ios-checkmark-circle" style="font-size: 38px;margin-bottom: 10px;" />
        <p style="font-size: 12px;color: #808695;padding-bottom: 20px;">支付成功</p>
      </div>
      <template v-else >
        <div v-if="time" style="display: flex;flex-direction: column;align-items: center;">
          <p style="font-size: 32px;color: #ff9900">￥{{price}}</p>
          <div id="qrcode" style="padding: 10px;border: 1px solid #dcdee2;margin-top: 10px;margin-bottom: 10px;"></div>
          <p style="font-size: 12px;color: #808695;margin-top: 10px;margin-bottom: 10px;">请在 <span style="color: #ff9900">{{ Math.floor(time/60) }}分{{ Math.floor(time%60) }}秒</span> 完成支付</p>
        </div>
        <div v-else style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 288px">
          <Icon color="#ff9900" type="md-warning" style="font-size: 38px;margin-bottom: 10px;" />
          <p style="font-size: 12px;color: #808695;padding-bottom: 20px;">订单已过期，您可关闭次弹窗重新提交</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import QRCode from 'qrcodejs2'

export default {
  name: 'Register',
  components: { Head, Footer },
  props: {},
  data () {
    const validateIdCard = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请填写身份证号码'))
      } else {
        callback()
      }
    }
    const validateTel = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('请输入手机号'))
      } else if (value.length !== 11) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      success: false,
      time: 300,
      qrcode: null,
      modal: false,
      price: '4.32',
      classOptions: [],
      formValidate: {
        jobName: '',
        classesId: '',
        companyName: '',
        eduProject: '',
        eduType: '',
        education: '',
        email: '',
        idCard: '',
        name: '',
        phone: '',
        sex: 0,
        wx: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入学员名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validateTel, rigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        idCard: [
          { required: true, validator: validateIdCard, trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change', type: 'number' }
        ],
        eduType: [
          { required: true, message: '请选择培训类型', trigger: 'change', type: 'number' }
        ],
        eduProject: [
          { required: true, message: '请选择培训项目', trigger: 'change', type: 'number' }
        ],
        classesId: [
          { required: true, message: '请选择班次', trigger: 'change' }
        ]
      },
      setInterval: null
    }
  },
  filter: {},
  computed: {},
  watch: {
    model (value) {
      if (!value) {
        this.success = false
        clearInterval(this.setInterval)
      }
    }
  },
  methods: {
    creatQrCode (url) {
      if (!this.qrcode) {
        this.qrcode = new QRCode('qrcode', {
          text: url,
          width: 160,
          height: 160,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      } else {
        this.qrcode.makeCode(url)
      }
    },
    // 获取班次
    getClass () {
      axios.get('https://peixun.asdsafe.cn:18001/trainCertifClasses/listAll').then(res => {
        if (res.data.code === 200) {
          this.classOptions = res.data.data.filter(item => item.state === 1 ? Math.round(new Date(item.classEndDate)) > Math.round(new Date()) : Math.round(new Date(item.classStartDate)) > Math.round(new Date()))
        }
      })
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = Object.assign({ type: 1 }, this.formValidate)
          axios.post('https://peixun.asdsafe.cn:18001/trainCertifStudent/gw/save', data).then(res => {
            if (res.data.code === 200) {
              this.time = this.$moment(res.data.data.time_expire).unix() - this.$moment().unix()
              this.price = res.data.data.price ? (res.data.data.price / 100).toFixed(2) : 0
              this.modal = true
              this.$nextTick(() => {
                this.creatQrCode(res.data.data.codeUrl)
              })
              // 定时更新当前时间
              if (this.time > 0) {
                this.setInterval = setInterval(() => {
                  if (this.time > 0) {
                    this.time--
                    axios.get(`https://peixun.asdsafe.cn:18001/wx/pay/transactions/payno?payNo=${res.data.data.pay_no}`).then(__res => {
                      if (__res.data.data === 'SUCCESS') {
                        this.success = true
                        this.$Message.success('支付成功')
                        this.time = 0
                        clearInterval(this.setInterval)
                      }
                    })
                  } else {
                    clearInterval(this.setInterval)
                  }
                }, 1000)
              } else {
                this.time = 0
              }
            } else {
              this.$Message.error('报名失败')
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    this.getClass()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.setInterval)
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
/deep/.ivu-modal-header {
  border: none;
  padding-bottom: 0;
}
</style>
