<template>
  <div>
    <nav>
      <div class="container display-flex ai-center jc-space-between">
        <img style="width: 317px;" src="@/assets/img/logo.png" alt="">
        <Menu style="width: calc(100% - 500px);" mode="horizontal"
              :active-name="$route.path" @on-select="(name) => {$router.push(name)}">
          <MenuItem name="/index">
            网站首页
          </MenuItem>
          <MenuItem name="/about">
            关于我们
          </MenuItem>
          <Submenu name="/service-project">
            <template slot="title">
              服务项目
            </template>
            <MenuItem name="/service-project/aqzx">安全咨询</MenuItem>
            <MenuItem name="/service-project/aqpj">安全评价</MenuItem>
            <MenuItem name="/service-project/gcxm">工程项目</MenuItem>
            <MenuItem name="/service-project/aqscxxh">安全生产信息化</MenuItem>
            <MenuItem name="/service-project/other">其他安全服务</MenuItem>
          </Submenu>
          <MenuItem name="/case">
            案例展示
          </MenuItem>
          <MenuItem name="/contact">
            联系我们
          </MenuItem>
<!--          <MenuItem name="/register">-->
<!--            培训报名-->
<!--          </MenuItem>-->
<!--          <MenuItem name="/notice">-->
<!--            信息公示-->
<!--          </MenuItem>-->
          <Submenu name="/notice-project">
            <template slot="title">
              信息公示
            </template>
            <MenuItem name="/notice-project/safe">安全评价</MenuItem>
            <MenuItem name="/notice-project/profession">职业卫生</MenuItem>
          </Submenu>
        </Menu>
        <div class="wechat display-flex ai-center cursor-pointer">
          <img class="mr-14" style="width: 25px;" src="@/assets/img/wechat-logo.png" alt="">
          <Poptip trigger="hover" placement="bottom">
            <span>安胜达官方微信</span>
            <div slot="content" style="width: 100px;">
              <img style="width: 100px;display: block" src="@/assets/img/wechat-code.png" alt="">
            </div>
          </Poptip>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Head',
  data () {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
  },
  created () {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-menu-horizontal .ivu-menu-item {
  padding: 0 30px;
}
/deep/ .ivu-menu-horizontal.ivu-menu-light:after {
  display: none;
}
header {
  background-color: #313131;
  color: #ffffff;
  font-size: 14px;
  height: 50px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
