<template>
  <div>
    <!--  公司介绍  -->
    <div class="container">
      <div class="company">
        公司介绍
      </div>
      <div class="slogan">
        化危为安，运筹制胜，使命必达
      </div>
      <div style="display: flex;align-items: center;justify-content: space-between;padding-bottom: 40px;">
        <div style="width: 0;flex: 1">
          <p>江苏安胜达安全科技有限公司成立于2014年，是一家具备HSE全栈式服务能力的机构；我们始终坚持“提供有价值的安全服务，创造有未来的个人空间”的核心价值观。</p>
          <p style="padding: 30px 0;">截止目前，公司已成功为多个行业领域的企业及各级政府单位客户提供了数万次的技术服务。我们能为客户提供HSE战略规划、全生命周期的咨询评估、职业健康、安全评价、安全培训、安全信息化、本质安全改造等服务，切实帮助客户构建以“生命健康安全”为核心的HSE管理体系。 </p>
          <Carousel
            autoplay
            loop
            arrow="never"
            radius-dot>
            <CarouselItem>
              <img style="width: 100%;" src="@/assets/img/gszp1.png" alt="">
            </CarouselItem>
            <CarouselItem>
              <img style="width: 100%;" src="@/assets/img/gszp2.png" alt="">
            </CarouselItem>
            <CarouselItem>
              <img style="width: 100%;" src="@/assets/img/gszp3.png" alt="">
            </CarouselItem>
            <CarouselItem>
              <img style="width: 100%;" src="@/assets/img/gszp4.png" alt="">
            </CarouselItem>
            <CarouselItem>
              <img style="width: 100%;" src="@/assets/img/gszp5.png" alt="">
            </CarouselItem>
          </Carousel>
        </div>
        <div style="width: 0;;flex: 1;margin-left: 40px;">
          <img src="@/assets/img/map.png" alt="">
        </div>
      </div>
      <div style="padding: 50px 0;background-color:#F9F9F9;display: flex;align-items: flex-start;justify-content: space-between;margin-bottom: 60px;">
        <div style="flex: 1;display: flex;flex-direction: column;align-items: center">
          <img src="@/assets/img/qyfz.png" alt="">
          <h5 style="font-size: 26px;padding: 14px;">愿景</h5>
          <p style="font-size: 20px;color: #666666;text-align: center">帮助客户实现风险来源可查、去向可追、责任可究、<br/>规律可循的智慧安全管理</p>
        </div>
        <div style="width: 1px;height: 108px;border: 1px solid #E6E6E6;margin-top: 20px;"></div>
        <div style="flex: 1;display: flex;flex-direction: column;align-items: center">
          <img src="@/assets/img/qymb.png" alt="">
          <h5 style="font-size: 26px;padding: 14px;">使命</h5>
          <p style="font-size: 20px;color: #666666;text-align: center">传递先进安全理念、提供专业安全服务、携手共筑<br/>安全基石</p>
        </div>
        <div style="width: 1px;height: 108px;border: 1px solid #E6E6E6;margin-top: 20px;"></div>
        <div style="flex: 1;display: flex;flex-direction: column;align-items: center">
          <img src="@/assets/img/jzg.png" alt="">
          <h5 style="font-size: 26px;padding: 14px;">价值观</h5>
          <p style="font-size: 20px;color: #666666;text-align: center">提供有价值的安全服务，创造有未来的个人空间</p>
        </div>
      </div>
    </div>
    <!--  价值观  -->
    <div class="gray">
      <h1 style="text-align:center;padding-top: 60px;">我们的团队</h1>
      <div class="picList" style="padding-top: 48px;">
        <div class="pic" style="margin: 0">
          <div style="margin-left: 0;">
            <img style="width: 100%;vertical-align: middle" src="@/assets/img/team1.png" alt="">
            <div class="title">安全服务团队（90+）</div>
            <div class="text">安胜达建立了包含安全评价师、注册安全工程师、标准化评审员、高级工程师、一级建筑师、一级注册消防工程师等专业工程师专业的安全专家服务团队，团队成员均拥有丰富的行业从业经验。</div>
          </div>
          <div>
            <img style="width: 100%;vertical-align: middle" src="@/assets/img/team2.png" alt="">
            <div class="title">职业卫生服务团队（40+）</div>
            <div class="text">职业卫生技术服务团队对技术人员实行培训制度，所有员工均经培训后上岗。团队现有职业卫生检测工程师和职业卫生评价工程师，其中有技术职称的高级工程师、中级工程师占比已达78.6%。</div>
          </div>
          <div>
            <img style="width: 100%;vertical-align: middle" src="@/assets/img/team3.png" alt="">
            <div class="title">信息化与安全产品团队（30+）</div>
            <div class="text">安胜达组建了安全信息化及安全产品团队，深耕安全生产数字化、信息化的研发与应用，团队综合运用物联网、大数据、云计算等前沿技术手段，打造以安胜达自主信息化产品构造的“安胜云”。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="safe" style="padding-bottom: 20px;">发展历程</div>
    <div style="margin-bottom: 30px;">
      <div class="container" style="padding-bottom: 20px;">
        <div class="progress">
          <i class="year" style="text-align: right;right: -12px;">2022-至今</i>
          <div class="line"></div>
          <div class="desc">
            <p>乘风破浪 开拓创新</p>
            <span>拓展服务行业领域；行业安全指南及手册制订；职业卫生技术服务机构资质；物联监测预警技术应用</span>
          </div>
        </div>
        <div class="progress">
          <div class="desc">
            <p>玉汝于成 溪达四海</p>
            <span>打造长三角2小时服务圈；探索“工业互联网+EHS服务”项目；安全评价机构资质/体系认证；设安全培训中心</span>
          </div>
          <div class="line"></div>
          <i class="year" style="text-align: left;right: 12px;">2020-2021</i>
        </div>
        <div class="progress">
          <i class="year" style="text-align: right;right: -12px;">2017-2019</i>
          <div class="line"></div>
          <div class="desc">
            <p>天道酬勤 厚积薄发</p>
            <span>开拓市场，拓展分公司；产学研用，与高校签订合作协议安全产品研发</span>
          </div>
        </div>
        <div class="progress">
          <div class="desc">
            <p>筚路蓝缕 以启山林</p>
            <span>2014年，安胜达安全科技有限公司成立公司走安全咨询服务之路，业务逐步拓展</span>
          </div>
          <div class="line" style="border: none"></div>
          <i class="year" style="text-align: left;right: 12px;">2014-2016</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyDesc'
}
</script>

<style scoped lang="scss">
.progress {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  .year {
    padding: 21px;
    flex: 1;
    font-size: 48px;
    color: #3CB133;
    position: relative;
  }
  .line {
    width: 1px;
    height: 100%;
    border: 0.5px solid #CCCCCC;
    margin: 0 30px;
    position: relative;
    top: 62px;
    &:before {
      position: absolute;
      top: -1px;
      left: -5px;
      content: ' ';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #3CB133;
    }
    &:after {
      position: absolute;
      top: -5px;
      left: -9px;
      content: ' ';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: rgba(60,177,51,0.39);
    }
  }
  .desc {
    flex: 1;
    padding: 20px;
    border: 1px solid #CDCDCD;
    p {
      font-size: 20px;
      margin-right: 30px;
      padding-bottom: 10px;
      font-weight: bold;
    }
    span {
      font-size: 16px;
      color: #666666;
    }
  }
}
.company {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 60px 0 15px 0;
}

.slogan {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-bottom: 40px;
}

.number {
  height: 105px;
  display: flex;
  margin-bottom: 40px;

  .item {
    flex: 1;
    text-align: center;
  }
}

.num {
  font-size: 48px;
  color: #333333;
}

.text {
  font-size: 16px;
  color: #666666;
}

.introduce {
  height: 320px;
  display: flex;
  margin-bottom: 60px;
}

.introduce div {
  flex: 1;
  font-size: 16px;
  color: #666666;
}

.content {
  margin-left: 30px;
}

.gray {
  width: 100%;
  background: #F2F2F2;
}

.picList {
  background: #F2F2F2;
  padding: 0 20px 60px;
  width: 1540px;
  margin: 0 auto;
}

.safe {
  padding: 60px 0px;
  font-weight: 500;
  color: #333333;
  font-size: 32px;
  text-align: center;
}

.pic {
  display: flex;
  margin: 0 10px;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin: 19px 0px 15px 0;
  }

  .text {
    padding: 0 60px 28px 60px;
    font-size: 14px;
    color: #999999;
    text-align: left;
  }
}

.pic > div {
  background: #FFFFFF;
  flex: 1;
  margin: 0px 10px;
  text-align: center;
}
</style>
