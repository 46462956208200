<template>
  <div>
    <!--  头部  -->
    <Head></Head>
    <!--  轮播  -->
    <ComCarousel></ComCarousel>
    <div class="container">
      <div class="content">
        <div class="contact">
          <h2 style="color:#0152d9;text-align: center;font-size: 30px">打造长三角2小时服务圈</h2>
          <h3 style="color:#595959;text-align: center;padding: 20px 0">立足园区，服务长三角，辐射全省，放眼全国</h3>
          <Form :label-width="100" class="mt-20">
            <FormItem label="联系电话：" class="mb-0">
              <p>0512-62763925</p>
            </FormItem>
            <FormItem label="E-mail：" class="mb-0">
              <p>admin@asdsafe.cn</p>
            </FormItem>
            <FormItem label="总公司地址：" class="mb-0">
              <p>苏州工业园区九章路69号理想创新大厦B座14F</p>
            </FormItem>
            <FormItem label="分公司分布：" class="mb-0">
              <p>南京 | 无锡 | 镇江 | 南通 | 宁波 | 太仓 | 昆山 | 常熟</p>
            </FormItem>
          </Form>
        </div>
        <baidu-map
          :center="center"
          :zoom="zoom"
          @ready="handler"
          class="map"
          ak="F8VWilj4E14qbNVLDQXZeFQYZHndbegi">
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-marker :position="{lng: 120.71048, lat: 31.347263}">
            <bm-label content="苏州总公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 120.416982, lat: 31.574188}">
            <bm-label content="无锡分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 120.948532, lat: 31.345232}">
            <bm-label content="昆山分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 121.144017, lat: 31.46814}">
            <bm-label content="太仓分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 120.778499, lat: 31.601076}">
            <bm-label content="常熟分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 119.37857, lat: 32.177104}">
            <bm-label content="镇江分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 118.720081, lat: 32.137685}">
            <bm-label content="南京分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
          <bm-marker :position="{lng: 121.489533, lat: 29.937517}">
            <bm-label content="宁波分公司" :labelStyle="{color: 'black', fontSize : '12px', border: 'none'}" :offset="{width: -20, height: -20}"/>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import ComCarousel from '@/components/ComCarousel'
export default {
  name: 'Contact',
  components: {
    ComCarousel,
    Head,
    Footer
  },
  props: {},
  data () {
    return {
      center: {
        lng: 0,
        lat: 0
      },
      zoom: 1
    }
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {
    handler ({ BMap, map }) {
      this.center.lng = 120.744617
      this.center.lat = 31.441312
      this.zoom = 10
    }
  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
/deep/.ivu-form-item-label {
  text-align: left !important;
}
.content {
  margin: 60px 0;
  display: flex;
  width: 100%;
  .contact {
    flex: 1;
    width: 100%;
    border: 1px solid #E6E6E6;
    padding: 30px 24px ;
  }
  .map {
    margin-left: 30px;
    flex: 2;
  }
}
</style>
