import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import 'normalize.css'
import '@/assets/style/common.scss'
import BaiduMap from 'vue-baidu-map'
import Moment from 'moment'
import 'moment/locale/zh-cn'
// api和自定义方法
import Storage from 'store'
import Api from '@/utils/Api.js'
Vue.prototype.$localStorage = Storage
Vue.prototype.$api = Api

Vue.use(BaiduMap, {
  ak: 'F8VWilj4E14qbNVLDQXZeFQYZHndbegi'
})
Vue.config.productionTip = false
Vue.prototype.$moment = Moment
Vue.use(ViewUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
