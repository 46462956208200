<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {},
  data () {
    return {

    }
  },
  watch: {},
  computed: {},
  methods: {
    onWindowResize () {
      if (document.body.clientWidth < 1400) {
        document.getElementById('app').style.zoom = '0.7'
      } else if (document.body.clientWidth < 1540) {
        document.getElementById('app').style.zoom = '0.9'
      } else {
        document.getElementById('app').style.zoom = '1'
      }
      window.onresize = () => {
        return (() => {
          console.log(document.body.clientWidth)
          if (document.body.clientWidth < 1400) {
            document.getElementById('app').style.zoom = '0.7'
          } else if (document.body.clientWidth < 1540) {
            document.getElementById('app').style.zoom = '0.9'
          } else {
            document.getElementById('app').style.zoom = '1'
          }
        })()
      }
    }
  },
  created () {
  },
  mounted () {
    if (!window.location.href.includes('h5')) {
      this.onWindowResize()
    }
  }
}
</script>
<style lang="scss">
</style>
