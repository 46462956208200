import Axios from 'axios'
/**
 * 封装get方法
 * @param {string} url - 接口地址
 * @param {object} params = {} - 接口参数
 * @returns {*} Promise
 */

export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: params
    })
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装delete方法
 * @param {string} url - 接口地址
 * @param {object} params = {} - 接口参数
 * @returns {*} Promise
 */

export function del (url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios.delete(url, {
      params: params
    })
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装delete方法
 * @param {string} url - 接口地址
 * @param  data - 接口参数
 * @returns {*} Promise
 */

export function delBody (url, data) {
  return new Promise((resolve, reject) => {
    Axios.delete(url, {
      data: data
    })
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param {string} url - 接口地址
 * @param {object} data = {} - 接口参数
 * @returns {*} Promise
 */

export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    Axios.post(url, data)
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装postQuery请求
 * @param {string} url - 接口地址
 * @param {object} params = {} - 接口参数
 * @returns {*} Promise
 */

export function postQuery (url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios.post(url, {}, {
      params: params
    })
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param {string} url - 接口地址
 * @param {object} data = {} - 接口参数
 * @returns {*} Promise
 */

export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    Axios.patch(url, data)
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装putQuery请求
 * @param {string} url - 接口地址
 * @param {object} params = {} - 接口参数
 * @returns {*} Promise
 */

export function putQuery (url, params) {
  return new Promise((resolve, reject) => {
    Axios.put(url, { }, {
      params: params
    })
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param {string} url - 接口地址
 * @param {object} data = {} - 接口参数
 * @returns {*} Promise
 */

export function put (url, data = {}, config) {
  return new Promise((resolve, reject) => {
    Axios.put(url, data, config)
      .then(response => {
        if (response.data.code === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }, err => {
        reject(err)
      })
  })
}
