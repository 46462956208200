<template>
  <Carousel
    v-if="show"
    autoplay
    loop
    arrow="never"
    radius-dot>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner1.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner2.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner3.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner4.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner5.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner6.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner7.png" alt="">
    </CarouselItem>
    <CarouselItem>
      <img style="width: 100%;" src="@/assets/img/banner8.png" alt="">
    </CarouselItem>
  </Carousel>
</template>

<script>
export default {
  name: 'ComCarousel',
  components: {},
  props: {},
  data () {
    return {
      show: false
    }
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    this.show = true
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>

</style>
