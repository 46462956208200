<template>
  <div>
    <!--  头部  -->
    <Head></Head>
    <!--  轮播  -->
    <ComCarousel></ComCarousel>
    <!--  安全咨询  -->
    <div class="container box">
      <div class="divider">
        <span class="left">安全</span>
        <span class="right">咨询</span>
      </div>
      <div class="aqzx">
        <div class="card" v-for="(item, index) in AQZXList" :key="index">
          <img :src="item.img" alt="">
          <div class="display-flex fd-column jc-space-between">
            <div>
              <b>{{ item.title }}</b>
              <p class="mt-10">{{ item.content }}</p>
            </div>
            <a :href="item.link">查看详情</a>
          </div>
        </div>
      </div>
    </div>
    <!--  安全评价  -->
    <div class="box" style="background-color:#ddecdc;">
      <div class="container">
        <div class="divider">
          <span class="left">安全</span>
          <span class="right">评价</span>
        </div>
        <div class="aqpj">
          <div>
            <h4>安全评价</h4>
            <p>安全评价也称为风险评价或危险评价，它是以实现安全为目的，应用安全系统工程原理和方法，辨识与分析工程、系统、生产经营活动中的危险、有害因素，预测发生事故或造成职业危害的可能性及其严重程度，提出科学、合理、可行的安全对策措施建议，做出评价结论的活动。</p>
            <b>针对项目的实施阶段，为客户提供安全生产条件和设施综合分析：</b>
            <div class="tags">
              <div @click="$router.push('/service-project/aqpj')">安全预评价</div>
              <div @click="$router.push('/service-project/aqpj')">安全设施设计</div>
              <div @click="$router.push('/service-project/aqpj')">安全验收评价</div>
              <div @click="$router.push('/service-project/aqpj')">安全设计诊断</div>
              <div @click="$router.push('/service-project/aqpj')">安全现状评价</div>
            </div>
          </div>
          <img src="@/assets/img/aqpj01.png" alt="">
        </div>
      </div>
    </div>
    <!--  工程项目  -->
    <div class="box">
      <div class="container">
        <div class="divider">
          <span class="left">工程</span>
          <span class="right">项目</span>
        </div>
      </div>
      <div class="gcxm container">
        <div class="tabs">
          <div @click="GCXMActive = 1" :class="{active: GCXMActive === 1}">粉尘防爆及其他安全设施改造</div>
          <div @click="GCXMActive = 2" :class="{active: GCXMActive === 2}">易燃易爆化学品防火柜</div>
          <div @click="GCXMActive = 3" :class="{active: GCXMActive === 3}">一般化学品防火柜</div>
          <div @click="GCXMActive = 4" :class="{active: GCXMActive === 4}">危险废弃物暂存柜</div>
          <div @click="GCXMActive = 5" :class="{active: GCXMActive === 5}">危险废弃物暂存柜</div>
          <div @click="GCXMActive = 6" :class="{active: GCXMActive === 6}">气瓶柜</div>
        </div>
        <div class="tabContent" v-if="GCXMActive === 1">
          <div>
            <h4>粉尘防爆及其他安全设施改造</h4>
            <p>除尘器设备组成</p>
            <span>灰斗、过滤室、净气室、支架、提升阀、喷吹清灰装置 </span>
            <p>除尘器工作原理</p>
            <span>工作时,含尘气体由风道进入灰斗。大颗粒的粉尘直接落入灰斗底部,较小的粉尘随气流转折向上进入过滤室,并被
阻留在滤袋外表面,净化了的烟气进入袋内,并经袋口和净气室进入出风道,由排风口排出 </span>
            <Button type="success" @click="$router.push('/service-project/gcxm?tabActive=1')">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm01.png" alt="">
        </div>
        <div class="tabContent" v-if="GCXMActive === 2">
          <div>
            <h4>易燃易爆化学品防火柜</h4>
            <p>适用于</p>
            <span>工贸等企业储存、使用易燃易爆物质，具有爆炸气体或蒸汽环境；海拔高度不超过 2000 米；25℃时，相对湿度 95％以下；工作环境温度-20℃～+40℃之间的环境</span>
            <p>柜体种类</p>
            <div class="category">
              <div>步入式</div>
              <div>层架式</div>
              <div>可定制</div>
            </div>
            <Button type="success" @click="$router.push('/service-project/gcxm?tabActive=2')">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm02.png" alt="">
        </div>
        <div class="tabContent" v-if="GCXMActive === 3">
          <div>
            <h4>一般化学品防火柜</h4>
            <p>柜体种类</p>
            <div class="category">
              <div>步入式</div>
              <div>层架式</div>
            </div>
            <p>产品优点</p>
            <span>产品结构紧凑，操作方便，适用范围广，使用安全可靠，符合法律法规要求，尺寸型号颜色均可根据客户需求定制，我们将委派专业技术人员为企业提供技术指导，真正做到安全存储</span>
            <Button type="success" @click="$router.push('/service-project/gcxm?tabActive=3')">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm03.png" alt="">
        </div>
        <div class="tabContent" v-if="GCXMActive === 4">
          <div>
            <h4>危险废弃物暂存柜</h4>
            <p>产品优点</p>
            <span>柜体框架强度高，多层油漆喷涂，拥有防火防爆认证，配备的防漏池为耐腐蚀型，容量可满足泄露容积比，防积水，底部安装了高为300毫米的支座，自带无人值守的设备及系统，信号输出稳定，抗干扰</span>
            <Button type="success" @click="$router.push('/service-project/gcxm?tabActive=4')">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm04.png" alt="">
        </div>
        <div class="tabContent" v-if="GCXMActive === 5">
          <div>
            <h4>化学品恒温柜</h4>
            <p>内容概述</p>
            <span>化学品恒温保存柜适用于恒温存储特定化学品，柜体整体焊接钢骨结构，内外壁经先进防腐化喷涂工艺涂层，防火、耐腐蚀、控温精度高，可持久使用</span>
            <Button type="success">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm05.png" alt="">
        </div>
        <div class="tabContent" v-if="GCXMActive === 6">
          <div>
            <h4>气瓶柜</h4>
            <p>产品可配备</p>
            <span>微电脑定时开关，高敏探头检测漏气自动排风，实现气体泄漏自动报警、时间显示、自动定时排风等多种功能，从而防止气体泄漏、燃爆等现象的发生</span>
            <Button type="success">查看详情</Button>
          </div>
          <img src="@/assets/img/gcxm06.png" alt="">
        </div>
      </div>
    </div>
    <!--  信息开发  -->
    <div class="box">
      <div class="container">
        <div class="divider">
          <span class="left">信息</span>
          <span class="right">开发</span>
        </div>
      </div>
      <div class="xxkf">
        <div class="container display-flex">
          <img src="@/assets/img/xxkf01.png" alt="">
          <div class="content">
            <h4>智慧安全管理平台介绍</h4>
            <p>追求在提升工业企业安全管理工作效率的基础上，提供安全环保信息化管理综合解决方案，通过将生产现场的实际情况，安全管理体系和信息化技术的结合，最终实现智慧安全管理。</p>
            <p>在企业的安全管理中，监控视频，巡检纪录，违章处理，整改情况等数据，都必须使用特殊化处理分析才能形成有规律、可预测的信息服务能力。通过对安全生产中产生的数据的利用，对安全生产周期性、关联性等特征进行分析，最终去实现来源可查，去向可追，责任可究，规律可循的安全管理。</p>
            <div class="category">
              <span><img src="@/assets/img/icon-zygxzd.png" alt="">专业高效指导</span>
              <span><img src="@/assets/img/icon-sgyc.png" alt="">事故预测</span>
              <span><img src="@/assets/img/icon-hybps.png" alt="">行业白皮书</span>
            </div>
            <Button type="primary" @click="$router.push('/service-project/aqscxxh')">查看详情</Button>
          </div>
        </div>
      </div>
    </div>
    <!--  新闻资讯  -->
    <div class="box">
      <div class="container">
        <div class="divider">
          <span class="left">新闻</span>
          <span class="right">资讯</span>
        </div>
        <div class="xwzx">
          <img src="https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2284240785,2213681483&fm=26&gp=0.jpg" alt="">
          <div class="content">
            <div class="header">
              <span @click="getNewsList"><Icon color="#3CB133" type="md-refresh" />点击换一批新闻</span>
<!--              <a href="">查看更多</a>-->
            </div>
            <ul>
              <li v-for="(item, index) in newsList" :key="index" @click="window.open(item.url)">
                <p>{{ item.title }}</p>
                <span>{{ item.date }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  公司介绍  -->
    <div class="gsjs">
      <div class="container">
        <h3>公司介绍</h3>
        <p>江苏安胜达安全科技有限公司成立于2014年，公司主要提供的服务有：咨询评估、安全评价、职业卫生检测与评价、安全工程改造、安全培训、危化品储存一体化解决方案、安全生产信息化解决方案和物联监测解决方案。</p>
        <div class="more" @click="$router.push('/about')">查看更多</div>
      </div>
    </div>
    <!--  案例展示  -->
    <div class="box">
      <div class="container">
        <div class="divider">
          <span class="left">案例</span>
          <span class="right">展示</span>
        </div>
        <div class="alzs">
          <div><img src="@/assets/img/case1.png" alt=""></div>
          <div><img src="@/assets/img/case2.png" alt=""></div>
          <div><img src="@/assets/img/case3.png" alt=""></div>
          <div><img src="@/assets/img/case4.png" alt=""></div>
          <div><img src="@/assets/img/case5.png" alt=""></div>
          <div><img src="@/assets/img/case6.png" alt=""></div>
          <div><img src="@/assets/img/case7.png" alt=""></div>
          <div><img src="@/assets/img/case8.png" alt=""></div>
          <div><img src="@/assets/img/case9.png" alt=""></div>
          <div><img src="@/assets/img/case10.png" alt=""></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import aqzx01 from '@/assets/img/aqzx01.png'
import aqzx02 from '@/assets/img/aqzx02.png'
import aqzx03 from '@/assets/img/aqzx03.png'
import aqzx04 from '@/assets/img/aqzx04.png'
import aqzx05 from '@/assets/img/aqzx05.png'
import aqzx06 from '@/assets/img/aqzx06.png'
import axios from 'axios'
import ComCarousel from '@/components/ComCarousel'
export default {
  name: 'Index',
  components: { ComCarousel, Head, Footer },
  data () {
    return {
      window,
      newsList: [],
      GCXMActive: 1,
      AQZXList: [
        {
          img: aqzx01,
          title: '双控',
          content: '为客户构筑“管风险”和“治隐患”的双重保护屏障，有效遏制生产安全事故的发生',
          link: '/#/service-project/aqzx?tabActive=1'
        },
        {
          img: aqzx02,
          title: '安全标准化',
          content: '一套即符合标准化要求又能有效管理企业安全生产风险的服务流程',
          link: '/#/service-project/aqzx?tabActive=2'
        },
        {
          img: aqzx03,
          title: '安全托管',
          content: '政府督导+第三方指导+企业主导',
          link: '/#/service-project/aqzx?tabActive=3'
        },
        {
          img: aqzx04,
          title: '隐患排查',
          content: '对企业现场进行排查，制定相应的整改措施，将企业安全隐患和安全生产事故消灭在萌芽状态',
          link: '/#/service-project/aqzx?tabActive=4'
        },
        {
          img: aqzx05,
          title: '应急预案',
          content: '指辅助企业进行事故风险辨识、评估，应急资源调查',
          link: '/#/service-project/aqzx?tabActive=5'
        },
        {
          img: aqzx06,
          title: '安全检查',
          content: '安全预评价、安全验收评价、安全现状评价、安全专项评价、重大危险源评估',
          link: '/#/service-project/aqzx'
        }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {
    getNewsList () {
      axios.get('https://oa.asdsafe.cn:18000/personal/listSafeNew').then(res => {
        if (res.data.code === 200) {
          const testArray = [...res.data.data.city, ...res.data.data.country, ...res.data.data.province]
          testArray.sort(function () { return Math.random() > 0.5 ? -1 : 1 })
          this.newsList = testArray.slice(0, 8)
        }
      })
    }
  },
  created () {

  },
  mounted () {
    this.getNewsList()
  }
}
</script>

<style lang="scss" scoped>
  h4 {
    font-size: 22px;
  }
  .box {
    padding: 60px 0;
  }
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    .left {
      position: relative;
      color: #3CB133;
      &:before {
        position: absolute;
        top: 24px;
        left: -110px;
        display: block;
        content: ' ';
        border-top: 1px solid #B3B3B3;
        width: 84px;
      }
    }
    .right {
      position: relative;
      &:after {
        position: absolute;
        top: 24px;
        left: 90px;
        display: block;
        content: ' ';
        border-top: 1px solid #B3B3B3;
        width: 84px;
      }
    }
  }
  .aqzx {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .card {
      cursor: pointer;
      width: 32%;
      margin: 10px;
      height: 200px;
      background: #FFFFFF;
      box-shadow: 0 2px 20px 0 rgba(205, 205, 205, 0.5);
      display: flex;
      img {
        width: 200px;
        height: 200px;
      }
      > div {
        font-size: 14px;
        padding: 20px 30px;
        b {
          font-size: 16px;
        }
        p {
          color: #666666;
        }
        a {
          color: #999999;
          text-decoration: underline;
          &:hover {
            color: #3CB133;
          }
        }
      }
    }
  }
  .aqpj {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 765px;
      height: 426px;
    }
    > div {
      background-color: #fff;
      height: 426px;
      padding: 30px 40px;
      p {
        color: #666666;
        font-size: 14px;
        margin: 20px 0;
        padding-bottom: 20px;
        border-bottom: 1px dashed #CCCCCC;
      }
      b {
        width: 100%;
        padding: 20px 0;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        div {
          cursor: pointer;
          width: 23%;
          text-align: center;
          padding: 15px 0;
          border-radius: 2px;
          border: 1px solid #D9D9D9;
          color: #666666;
          margin: 0 10px 10px 0;
          &:hover {
            color: #3CB133;
            border: 1px solid #3CB133;
          }
        }
      }
    }
  }
  .gcxm {
    .tabs {
      margin: 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        cursor: pointer;
        width: calc(16% + 8px);
        height: 45px;
        color: #666666;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active {
        background-color: #3CB133;
        color: #FFFFFF;
      }
    }
    .tabContent {
      border: 1px solid #CDCDCD;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px;
      img {
        height: 280px;
        margin: 0 80px;
      }
      > div {
        p {
          color: #3CB133;
          margin: 20px 0;
          text-indent: 16px;
          position: relative;
          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            top: 6px;
            width: 8px;
            height: 8px;
            background-color: #3CB133;
            border-radius: 50%;
          }
        }
      }
      .category {
        display: flex;
        > div {
          color: #666666;
          width: 108px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #CDCDCD;
          margin-right: 20px;
        }
      }
      button {
        margin-top: 20px;
        display: block;
      }
    }
  }
  .xxkf {
    margin-top: 40px;
    background-image: url('~@/assets/img/xxkf-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
    h4 {
      font-size: 22px;
    }
    img {
      height: 438px;
    }
    .content {
      width: 100%;
      background-color: #fff;
      height: 438px;
      padding: 40px 30px;
      p {
        padding: 30px 0;
        color: #666666;
      }
      .category {
        font-size: 18px;
        margin-bottom: 26px;
        span {
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          padding: 0 20px;
          border-left: 1px solid #D8D8D8;
          &:nth-child(1) {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }
  }
  .xwzx {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 770px;
      height: 413px;
      flex: 1;
    }
    .content {
      padding-top: 6px;
      margin-left: 20px;
      height: 413px;
      font-size: 14px;
      flex: 1;
      span {
        cursor: pointer;
        color: #999999;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        span {
          position: relative;
          left: 3px;
        }
        a {
          color: #333333;
        }
        i {
          position: relative;
          top: -1px;
          left: -5px;
        }
      }
      ul {
        list-style: none;
        li {
          cursor: pointer;
          height: 48px;
          border-top: 1px solid #E6E6E6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:hover {
            color: #3CB133;
          }
          p {
            position: relative;
            text-indent: 16px;
            &:before {
              content: ' ';
              position: absolute;
              left: 0;
              top: 6px;
              width: 8px;
              height: 8px;
              background-color: #3CB133;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .gsjs {
    height: 340px;
    background-image: url("~@/assets/img/gsjs-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #FFFFFF;
    text-align: center;
    h3 {
      padding-top: 60px;
      font-size: 32px;
    }
    p {
      margin: 30px 0 40px 0;
    }
    .more {
      margin: 0 auto;
      width: 195px;
      height: 60px;
      background: #0D7EE3;
      border-radius: 2px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .alzs {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div {
      width: 140px;
      height: 130px;
      border: 1px solid #CCCCCC;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
