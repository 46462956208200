import { render, staticRenderFns } from "./Safe.vue?vue&type=template&id=4184d2e9&scoped=true&"
import script from "./Safe.vue?vue&type=script&lang=js&"
export * from "./Safe.vue?vue&type=script&lang=js&"
import style0 from "./Safe.vue?vue&type=style&index=0&id=4184d2e9&lang=scss&scoped=true&"
import style1 from "./Safe.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4184d2e9",
  null
  
)

export default component.exports