<template>
  <div>
    <!--  头部  -->
    <Head></Head>
    <!--  轮播  -->
    <ComCarousel></ComCarousel>
    <div class="container alzs">
      <div><img src="@/assets/img/case-logo (1).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (2).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (3).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (4).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (5).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (6).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (7).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (8).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (9).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (10).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (11).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (12).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (13).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (14).png" alt=""></div>
      <div><img src="@/assets/img/case-logo (15).png" alt=""></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/Head'
import Footer from '@/components/Footer'
import ComCarousel from '@/components/ComCarousel'

export default {
  name: 'Case',
  components: { ComCarousel, Head, Footer },
  props: {},
  data () {
    return {}
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
.alzs {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div {
    margin: 10px;
    width: calc(25% - 95px);
    height: 280px;
    border: 1px solid #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
